.marketplace-otc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px; /* Add padding to avoid header overlap */
  background-color: #faf4f8; /* Set background color to match Home */
  min-height: 100vh; /* Ensure the content covers the full viewport height */
}

.rules {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
}

.rules ul {
  list-style-type: none;
  padding: 0;
}

.rules li {
  margin-bottom: 10px;
  font-weight: 700;
}

.x-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8087b; /* Use the same color as other buttons */
  color: white;
  border-radius: 24px;
  font-weight: 700;
  text-decoration: none;
  margin-top: 20px;
}

.x-button .fa-telegram {
  margin-right: 10px;
}

.content {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.new-order-btn {
  background-color: #f8087b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 24px; /* Set border radius for buttons */
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 20px;
}

.order-form {
  margin-bottom: 20px;
}

.order-form label {
  display: block;
  margin-bottom: 10px;
}

.order-form input,
.order-form select {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.order-form button {
  background-color: #f8087b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 24px; /* Set border radius for buttons */
  font-weight: 700;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff; /* Set background color to white */
  border-radius: 24px; /* Add border radius */
  overflow: hidden; /* Ensure the border radius is applied */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visual effect */
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f8087b;
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #f8087b;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 24px; /* Ensure button border radius matches */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content button,
.dropdown-content input {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background: none;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .rules {
    padding: 10px;
  }

  .x-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .content {
    padding: 10px;
  }

  h2 {
    font-size: 24px;
  }

  .new-order-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .order-form label {
    margin-bottom: 5px;
  }

  .order-form input,
  .order-form select {
    padding: 6px;
  }

  .order-form button {
    padding: 8px 16px;
    font-size: 14px;
  }

  table {
    margin-top: 10px;
  }

  th,
  td {
    padding: 6px;
    font-size: 14px;
  }

  .dropbtn {
    padding: 8px;
    font-size: 14px;
  }

  .dropdown-content button,
  .dropdown-content input {
    padding: 10px 12px;
  }
}
