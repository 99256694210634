.marketplace-otc-section {
  padding: 50px 20px;
  background-color: #faf4f8;
  color: #2a171f;
}

.marketplace-otc-section h2 {
  font-size: 45px;
  background: linear-gradient(
    79.7deg,
    hsla(11, 81%, 74%, 0.68) 5.55%,
    #ff3b7b 55.23%,
    rgba(24, 165, 184, 0.2) 98.71%,
    rgba(171, 24, 184, 0.25) 131.11%,
    rgba(242, 44, 109, 0.38) 157.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  text-align: center;
}

.marketplace-otc-section .content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.marketplace-otc-section .text-content {
  flex: 1;
}

.marketplace-otc-section .p2p-image {
  max-width: 400px;
  border-radius: 24px;
  flex-shrink: 0;
}

.marketplace-otc-section ul {
  list-style-type: none;
  padding-left: 0;
}

.marketplace-otc-section ul li {
  font-size: 18px;
  margin: 20px 0;
  display: flex;
  align-items: flex-start;
}

.marketplace-otc-section ul li .icon {
  color: #ff3b7b; /* Use the color from the title */
  margin-right: 10px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.telegram-button,
.marketplace-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8087b; /* Use the same color as other buttons */
  color: white;
  border-radius: 24px;
  font-weight: 700;
  text-decoration: none;
  font-size: 18px;
}

.telegram-button .fa-telegram {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .marketplace-otc-section .content {
    flex-direction: column;
    align-items: center;
  }

  .marketplace-otc-section .p2p-image {
    margin-top: 20px;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
