.tokenomics {
  padding: 50px 20px;
  text-align: center;
  background-color: #faf4f8;
  color: #2a171f;
}

.tokenomics h2 {
  font-size: 55px;
  background: linear-gradient(
    79.7deg,
    hsla(11, 81%, 74%, 0.68) 5.55%,
    #ff3b7b 55.23%,
    rgba(24, 165, 184, 0.2) 98.71%,
    rgba(171, 24, 184, 0.25) 131.11%,
    rgba(242, 44, 109, 0.38) 157.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tokenomics-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.chart-container {
  max-width: 400px;
  width: 100%;
}

.token-description {
  margin-top: 20px;
  max-width: 600px;
}
