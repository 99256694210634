.milei-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7cad5; /* Background color for the banner */
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.milei-image {
  height: 500px; /* Set the height of the image to 500px */
  margin-right: 20px;
  border-radius: 10px;
  object-fit: cover; /* Ensure the image covers the entire height */
}

.milei-quote {
  font-size: 20px;
  color: #2a171f;
  text-align: center;
  font-weight: 700; /* Set font weight to 700 */
}

/* Responsive Design */
@media (max-width: 768px) {
  .milei-banner {
    flex-direction: column; /* Stack items vertically */
    padding: 20px;
  }

  .milei-image {
    height: auto; /* Let the image resize naturally */
    width: 100%; /* Ensure the image takes the full width */
    margin-right: 0;
    margin-bottom: 20px; /* Add some space between the image and the quote */
  }

  .milei-quote {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
}
