body,
html {
  margin: 0;
  padding: 0;
  background-color: #faf4f8; /* Set the background color for the entire page */
  font-family: "Poppins", sans-serif; /* Ensure the font family is consistent */
}

#root {
  background-color: #faf4f8; /* Ensure the root element has the correct background color */
}
