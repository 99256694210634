.home {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #faf4f8; /* Ensure background color matches the overall background */
}

/* Responsive Design */
@media (max-width: 768px) {
  .home {
    padding: 10px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}
