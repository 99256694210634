.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  background-color: #faf4f8;
  flex-wrap: wrap;
}

.hero-text {
  max-width: 600px;
  flex: 1;
  margin-right: 20px;
}

.hero h1 {
  font-size: 45px;
  background: linear-gradient(
    79.7deg,
    hsla(11, 81%, 74%, 0.68) 5.55%,
    #ff3b7b 55.23%,
    rgba(24, 165, 184, 0.2) 98.71%,
    rgba(171, 24, 184, 0.25) 131.11%,
    rgba(242, 44, 109, 0.38) 157.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 20px 0;
}

.hero p {
  font-size: 20px;
  color: #2a171f;
  margin: 0 0 20px 0;
}

.hero .cta-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.hero .cta {
  background-color: #f8087b;
  padding: 10px 20px;
  border-radius: 24px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
}

.hero-image {
  max-width: 400px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  flex: 1;
  position: relative;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hero-text {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .hero h1 {
    font-size: 35px;
  }

  .hero p {
    font-size: 16px;
  }

  .hero .cta {
    font-size: 16px;
  }

  .hero-image {
    margin-bottom: 20px;
  }
}
