.roadmap {
  padding: 50px 20px;
  text-align: center;
  background-color: #faf4f8;
  color: #2a171f;
}

.roadmap h2 {
  font-size: 55px;
  background: linear-gradient(
    79.7deg,
    hsla(11, 81%, 74%, 0.68) 5.55%,
    #ff3b7b 55.23%,
    rgba(24, 165, 184, 0.2) 98.71%,
    rgba(171, 24, 184, 0.25) 131.11%,
    rgba(242, 44, 109, 0.38) 157.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmap-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto; /* Add horizontal scrolling if needed */
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
}

.card h3 {
  font-size: 24px;
  background: linear-gradient(
    79.7deg,
    hsla(11, 81%, 74%, 0.68) 5.55%,
    #ff3b7b 55.23%,
    rgba(24, 165, 184, 0.2) 98.71%,
    rgba(171, 24, 184, 0.25) 131.11%,
    rgba(242, 44, 109, 0.38) 157.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.card p {
  color: #2a171f;
  font-size: 16px;
}
