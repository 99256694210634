.footer {
  background-color: #faf4f8;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer-content p {
  margin: 0;
  font-size: 14px;
  color: #2a171f;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #2a171f;
  font-size: 24px;
}

.social-icons a:hover {
  color: #f8087b;
}
