.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px; /* Adjust padding as needed */
  background-color: #faf4f8;
}

.header .logo {
  height: 80px;
}

.nav {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.nav a {
  color: #2a171f;
  margin: 0 10px;
  text-decoration: none;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.cta-container {
  display: flex;
  align-items: center;
}

.cta {
  background-color: #f8087b;
  padding: 10px 20px;
  border-radius: 24px;
  font-weight: 700;
  color: #ffffff; /* Ensure the text is white */
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.cta .fa-x-twitter,
.cta .fa-telegram {
  margin-right: 10px;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #2a171f;
  margin: 4px 0;
}

@media (max-width: 768px) {
  .header {
    padding: 20px;
  }

  .nav {
    flex-direction: column;
    display: none;
    width: 100%;
    text-align: center;
  }

  .nav.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .cta {
    display: none; /* Hide CTA button in responsive mode */
  }
}
